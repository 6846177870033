.gameBtn {

    max-width: 100px;
    height: 1.2rem;       
    background: #cc1a02;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 5px; 
    
}

.gameBtn:hover{
    background: #f31f03;
}

