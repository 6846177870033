.rank{
    background: #dfdfdf;
    opacity: 0.9;
    color: #363636;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px;
}

.caption{
    font-weight: bolder;
    margin: 3px;
}

.cell{
    margin: 1px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-align: center;
    background: #f0efef;;
    opacity: 1;
    
}
