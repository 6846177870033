@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Luckiest+Guy&family=Press+Start+2P&display=swap');


.gameManager{
    font-family: 'Press Start 2P', cursive;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.outWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gameInner{
    font-family: 'Press Start 2P', cursive;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: auto;
}

.imageBlock{
    display: flex;

}

.modalImage{
    width: 40px;
    height: 40px;
  }
