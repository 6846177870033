.block{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.blockImage{
    width: 30px;
    height: 30px;
  }

.block-inactive{
    background-color: #6F1AB6;
    
}

.block-inactive:hover{
    background-color: #a02bff;
    padding: 0.55rem;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0);
}

.block-free{
    background-color: #227C70;
}

.block-danger{
    background-color: #CD0404;
}  


.block-danger-nostep{
    background-color: #e6cd82;
}   


