
.navInner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.nav{
    display: flex;
    align-items: center;
    gap:10px;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    background-color: #678983;
    font-family: 'Audiowide', cursive;
}

.bombNav{
    width: 50px;
    height: 50px;
}

.bg{
    margin-left: 5px;  
}

.none{
    display: none;
}

.bg:hover{
    opacity: 0.7;
}