.gameForm{
    font-size: 0.7rem;
    background-color: #dfdfdf;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    gap: 15px;
    width: 250px;
    height: 150px;
    margin: 20px;
    padding: 20px;
}

.gameFormLess{
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    gap: 10px;
    margin: 20px;
}

.inputWrap{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.input{
    font-size: 16px;
    height: 1.2rem;
    width: 50px;
}

.largeInput{
    font-size: 16px;
    height: 1.2rem;
    width: 100px;
}