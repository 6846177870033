.modal{
    display: flex;
    gap: 5px;
    color: #ffffff;
    margin: 10px;
    background: #3D1766;
    border-radius: 5px;
    padding: 5px;
    font-size: 0.7rem;
}


