.smallBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #3D1766;
    height: 20%;
    border-radius: 5px;
    padding: 5px;
}

.strongModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin: 10px;
    background: #a767eb;
    opacity: 80%;
    max-width: 400px;
    border-radius: 5px;
    padding: 5px;

}

.bombImage {
    width: 100px;
    height: 100px;
  }
